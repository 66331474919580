<template>
  <div class="aiz-user-panel order-page">

    <div class="aiz-titlebar mt-2 mb-2">
      <div class="row align-items-center">
        <div class="col-md-6">
            <h1 class="h3">{{ $t('ding-dan') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-10">
      <div class="col-md-12 mx-auto mb-4" >
        <div class="order-bg text-white rounded-lg overflow-hidden">

        </div>
      </div>
    </div>

    <div class="row gutters-10">
      <div class="col-md-3 col-xs-6 mx-auto mb-3" v-if="false" >
        <div class="statistics-box">
          <div class="title">
            <span>{{ $t('hui-yuan-ji-bie') }}</span>
            <img :src="orderIcon1" alt="">
          </div>
          <div class="content">
            <img :src="levelObj[`level${userLevel}`] || levelUrl" alt="">
            <span>VIP {{userLevel}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-xs-6 mx-auto mb-3" >
        <div class="statistics-box">
          <div class="title">
            <span>{{ $t('zhang-hu-jin-e') }}</span>
            <img :src="orderIcon4" alt="">
          </div>
          <div class="content">
            <span>${{userMoney}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-xs-6 mx-auto mb-3" >
        <div class="statistics-box">
          <div class="title">
            <span>{{ $t('ren-wu-yong-jin') }}</span>
            <img :src="orderIcon2" alt="">
          </div>
          <div class="content">
            <span>${{taskCommissionData.OrderCommission.toFixed(2)}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-xs-6 mx-auto mb-3" >
        <div class="statistics-box">
          <div class="title">
            <span>{{ $t('ren-wu-shu-liang') }}</span>
            <img :src="orderIcon3" alt="">
          </div>
          <div class="content">
            <span>{{taskCommissionData.FinishCount}}/{{taskCommissionData.OrderCount}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-xs-6 mx-auto mb-3" >
        <div class="statistics-box">
          <div class="title">
            <span>{{ $t('Frozen amount') }}</span>
            <img :src="orderIcon4" alt="">
          </div>
          <div class="content">
            <span>${{freezeMoney}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <button type="button" class="order-btn btn btn-primary" @click="submit">{{ $t('huo-qu-ding-dan') }}</button>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <div class="role-box" :class="showRule ? '' : 'hide'">
          <div class="title" @click="changeShow('rule')">
            <span>{{ $t('ren-wu-gui-ze') }}</span>
            <span class="arrow"></span>
          </div>
          <div class="content">
            {{ $t('order-desc1') }}<br />
            {{ $t('order-desc2',[userInfo.Leave.Min]) }}<br />
            {{ $t('order-desc3') }}<br />
            {{ $t('order-desc4',[taskCommissionData.OrderCount,taskCommissionData.OrderCount]) }}<br />
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <div class="role-box " :class="showDesc ? '' : 'hide'">
          <div class="title" @click="changeShow('desc')">
            <span>{{ $t('ren-wu-jie-shao') }}</span>
            <span class="arrow"></span>
          </div>
          <div class="content">
            {{ appName }}{{ $t('order-desc5') }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { resetPrice } from '@/utils/common'
import {taskCommission, taskCreate, userInfo} from '@/api/user'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      orderIcon1: require('../../assets/imgs/icon-level.png'),
      orderIcon2: require('../../assets/imgs/icon-commission.png'),
      orderIcon3: require('../../assets/imgs/icon-count.png'),
      orderIcon4: require('../../assets/imgs/icon-money.png'),
      levelUrl: require('../../assets/imgs/level1.png'),
      levelObj: {
        level1: require('../../assets/imgs/level1.png'),
        level2: require('../../assets/imgs/level2.png'),
        level3: require('../../assets/imgs/level4.png'),
        level4: require('../../assets/imgs/level4.png'),
        level5: require('../../assets/imgs/level6.png'),
        level6: require('../../assets/imgs/level6.png'),
      },
      loadingUrl: require('../../assets/imgs/car.gif'),
      showRule: true,
      showDesc: true,
      userLevel: 1,
      taskCommissionData: {
        FinishCount: '0',
        OrderCount: '0',
        OrderCommission: 0
      },
      userMoney: '0.00',
      freezeMoney: '0.00',
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    appName(){
      return this.$store.state.appName
    }
  },
  watch: {
    userInfo(val) {
      if (val.Leave) {
        this.userLevel = this.userInfo.Leave.Leave
        console.log("userLevel",this.userLevel)
      }
      if (val.ID) {
        this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
      }
    }
  },
  mounted() {
    if (this.userInfo.Leave) {
      this.userLevel = this.userInfo.Leave.Leave
    }
    if (this.userInfo.ID) {
      this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
    }
    this.init()
  },
  methods: {
    resetPrice,
    init() {
      taskCommission().then(res => {
        this.taskCommissionData = res.data
      })
      userInfo().then(res => {
        this.userMoney = parseFloat(res.data.UserAccount.Balance).toFixed(2)
        this.freezeMoney = parseFloat(res.data.UserAccount.Freeze).toFixed(2)
      })
    },
    changeShow(type) {
      if (type == 'desc') {
        this.showDesc = !this.showDesc
      } else {
        this.showRule = !this.showRule
      }
    },
    submit() {
      taskCreate().then(res => {
				if (res.code == 0) {
					let data = res.data
          sessionStorage.setItem('taskDetail', JSON.stringify(data))
          this.$router.push({
            name: 'taskDetail'
          })
				} else {
					this.$toast.fail(res.msg)
				}
			})

    }
  }
}
</script>